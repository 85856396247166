<template>
  <div class="carousel" ref="carousel" :class="{ 'carousel-enter': isVisible }">
    <div
      class="carousel-container"
      :style="{
        transform: `translateX(-${transitionIndex * 100}%)`,
        transitionDuration: isTransitioning ? '0.5s' : '0s',
      }"
      @transitionend="handleTransitionEnd"
    >
      <!-- 克隆的最后一张放在最前面 -->
      <div
        class="carousel-item"
        :style="{ backgroundImage: `url(${images[images.length - 1]})` }"
      ></div>

      <!-- 原始图片 -->
      <div
        v-for="(image, index) in images"
        :key="index"
        class="carousel-item"
        :style="{ backgroundImage: `url(${image})` }"
      ></div>

      <!-- 克隆的第一张放在最后面 -->
      <div
        class="carousel-item"
        :style="{ backgroundImage: `url(${images[0]})` }"
      ></div>
    </div>

    <!-- 指示器（可选） -->
    <div class="carousel-indicators" v-if="indicator">
      <span
        v-for="(image, index) in images"
        :key="index"
        class="indicator"
        :class="{ active: index === currentIndex }"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true, // 必须传入一个图片数组
    },
    interval: {
      type: Number,
      default: 3000, // 默认自动切换时间为3秒
    },
    indicator: {
      type: Boolean,
      default: true, // 默认显示指示器
    },
  },
  data() {
    return {
      currentIndex: 0, // 当前显示的图片索引
      transitionIndex: 1, // 轮播容器的实际显示索引
      isTransitioning: true, // 是否应用过渡动画
      animationFrame: null, // requestAnimationFrame 的 ID

      imagesLoaded: false,
      isVisible: false, // 控制淡入效果的变量
    };
  },
  mounted() {
    this.preloadImages().then(() => {
      this.imagesLoaded = true;
      this.isVisible = true; // 图片加载后触发淡入
      this.startAutoSlide();
    });
    // this.startAutoSlide();
  },
  beforeDestroy() {
    this.stopAutoSlide();
  },
  methods: {
    preloadImages() {
      return Promise.all(
        this.images.map((src) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
          });
        })
      );
    },
    startAutoSlide() {
      const animate = (timestamp) => {
        if (!this.lastTimestamp) this.lastTimestamp = timestamp;
        const elapsed = timestamp - this.lastTimestamp;
        if (elapsed >= this.interval) {
          this.nextSlide();
          this.lastTimestamp = timestamp;
        }
        this.animationFrame = requestAnimationFrame(animate);
      };
      this.animationFrame = requestAnimationFrame(animate);
    },
    stopAutoSlide() {
      cancelAnimationFrame(this.animationFrame);
    },
    nextSlide() {
      this.isTransitioning = true;
      this.transitionIndex++;
    },
    goToSlide(index) {
      this.currentIndex = index;
      this.isTransitioning = true;
      this.transitionIndex = index + 1;
    },
    handleTransitionEnd() {
      // 当从最后一张克隆图片过渡到真实第一张时
      if (this.transitionIndex === this.images.length + 1) {
        this.transitionIndex = 1;
        this.isTransitioning = false;
      }

      // 当从第一张克隆图片过渡到真实最后一张时
      if (this.transitionIndex === 0) {
        this.transitionIndex = this.images.length;
        this.isTransitioning = false;
      }

      // 更新当前图片索引
      this.currentIndex =
        this.transitionIndex === this.images.length + 1
          ? 0
          : this.transitionIndex === 0
          ? this.images.length - 1
          : this.transitionIndex - 1;
    },
  },
};
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;

  transition: opacity 0.4s linear, filter 0.4s linear;
  opacity: 0;
  filter: blur(10px);
}

.carousel-enter {
  opacity: 1;
  filter: unset;
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.carousel-item {
  min-width: 100%;
  height: 100%;
  aspect-ratio: 1920 / 700;
  background-size: cover;
  background-position: center;
}

/* 可选：指示器样式 */
.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.indicator {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.indicator.active {
  background-color: #fff;
}
</style>
